<template>
  <div class="reward_warp">
    <van-overlay :show="isRewardShow" />
    <transition name="fade">
      <div class="reward_pop_box" v-if="isRewardShow">
        <div class="reward_pop">
          <h2>{{ title }}</h2>
          <div class="reward">
            <div
              class="reward_item"
              :class="{ active: selectItem == i }"
              v-for="i in amountList"
              :key="i"
              @click="selectAmout(i)"
            >
              <svg-icon icon-class="gold" />
              {{ i }}
            </div>
            <div class="reward_item">
              <van-field
                type="number"
                v-model="customizeMoney"
                placeholder="其他金额"
                @focus="inputAmount"
              />
            </div>
          </div>
          <div class="balance">钱包余额：{{ walletBalance }}</div>
          <div class="reward_btn" @click="confirm">确定</div>
        </div>
        <div class="close_box" @click="close">
          <div class="close">
            <svg-icon icon-class="y_close" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { Overlay, Field, Toast } from 'vant';

export default {
  components: {
    [Overlay.name]: Overlay,
    [Field.name]: Field,
  },
  props: {
    isRewardShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '为喜欢的UP主加油',
    },
    amountList: {
      type: Array,
      default: () => [1, 5, 10, 50, 100],
    },
  },
  computed: {
    walletBalance() {
      let walletInfo = this.$store.getters.walletInfo;
      let walletBalance = (
        walletInfo.amount ||
        0 + walletInfo.income ||
        0
      ).toFixed(2);
      return walletBalance || 0.0;
    },
  },
  data() {
    return {
      customizeMoney: '',
      selectItem: '',
    };
  },
  created() {
    this.$store.dispatch('user/getWallet');
  },
  methods: {
    // 选择金币
    selectAmout(i) {
      this.selectItem = i;
      this.customizeMoney = '';
    },
    // 获取焦点清除选择
    inputAmount() {
      this.selectItem = '';
    },
    close() {
      this.customizeMoney = '';
      this.selectItem = '';
      this.$emit('close');
    },
    // 确认金额
    confirm() {
      if (!Number(this.customizeMoney) && !this.selectItem) {
        Toast('请选择或输入金币');
        return;
      }
      if (
        Number(this.customizeMoney) &&
        this.customizeMoney > this.walletBalance
      ) {
        // this.$router.push('/memberCentre?t=wallet');
        Toast('余额不足，请前往充值');
        return;
      }
      if (this.selectItem && this.selectItem > this.walletBalance) {
        // this.$router.push('/memberCentre?t=wallet');
        Toast('余额不足，请前往充值');
        return;
      }
      let amonut = this.customizeMoney ? this.customizeMoney : this.selectItem;
      this.$emit('confirm', amonut);
    },
  },
};
</script>
<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.reward_pop_box {
  position: fixed;
  max-height: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  z-index: 12;
  border-radius: 17px;
}

/deep/ .van-overlay {
  z-index: 11;
}

.reward_pop {
  width: 294px;
  background: url('../../assets/png/reward_bg.png') no-repeat;
  background-size: cover;
  border-radius: 17px;
  color: #171717;
  padding: 0 15px 24px;
  position: relative;

  h2 {
    font-size: 16px;
    padding: 18px 0 20px 0;
    text-align: center;
    color: #333333;
  }

  .reward {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 8px;
    grid-column-gap: 8px;

    .reward_item {
      text-align: center;
      //border: 2px solid #ff7600;
      border-radius: 4px;
      background: #fff4f7;
      height: 39px;
      line-height: 39px;
      font-size: 18px;

      /deep/ .van-field {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        background: #fff4f7;
      }

      /deep/ .van-field__control {
        text-align: center;
      }
    }

    .active {
      background: #ff678f;
      color: #fff;
    }
  }

  .balance {
    font-size: 12px;
    padding: 16px 0 25px 0;
    color: #666666;
  }

  .reward_btn {
    width: 228px;
    height: 36px;
    line-height: 36px;
    background: #ff678f;
    font-size: 15px;
    text-align: center;
    border-radius: 20px;
    color: white;
    margin: 0 auto;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
  }
}

.close_box {
  position: absolute;
  bottom: -70px;
  width: 100%;
  display: flex;
  justify-content: center;

  .close {
    svg {
      font-size: 33px;
    }
  }
}
</style>
