<template>
  <div class="post_detiils">
    <div class="header">
      <div class="back" @click="$router.go(-1)">
        <svg-icon icon-class="back"></svg-icon>
      </div>
      <h2>帖子正文</h2>
    </div>
    <div class="content" v-if="!isLoading">
      <!--帖子-->
      <Post class="post" v-if="postInfo.id" :postInfo="postInfo" :isMenuClose="true" />
      <!-- 赞赏-->
      <div class="appreciate">
        <h2>点赞是美意，打赏是鼓励</h2>
        <div class="appreciate_btn" @click="showReward">
          <div class="avatar">
            <ImgDecypt :src="postInfo.publisher && postInfo.publisher.portrait" round />
          </div>
          喜欢作者
        </div>
      </div>
      <!--  广告-->
      <AdvSwiper :advList="advList" class="adv" height="111px" />
      <!-- 评论-->
      <!-- <div class="comment">
        <h2>评论</h2>
        <div class="comment_content">
          <Comment :videoId="id" :isInputShow="isSendShow" :disabled="true" @close="isSendShow=false" :isPopShow="true"
                   :autofocus="true" @success="success" @parReply="parReply" :externalReply="true"/>
        </div>
      </div> -->
      <div class="bottom_menu" v-show="!isSendShow">
        <div class="views">
          <svg-icon icon-class="tip_eye"></svg-icon>
          浏览次数:
          <span>{{ postInfo.playCount | watchCount }}</span>
        </div>
        <div class="menu_box">
          <div class="menu_item">
            <div @click.stop="openShare">
              <svg-icon icon-class="share_video"></svg-icon>
            </div>
            <span class="ellipsis">赚钱</span>
          </div>
          <div class="menu_item">
            <div @click.stop="message">
              <svg-icon icon-class="message"></svg-icon>
            </div>
            <span class="ellipsis">{{ postInfo.commentCount || '评论' }}</span>
          </div>
          <div class="menu_item" @click="collecte">
            <svg-icon :icon-class="postInfo.vidStatus && postInfo.vidStatus.hasCollected ? 'tip_like_active' : 'tip_like'" />
            <span class="ellipsis">
              {{ (postInfo.forwardCount || '点赞') | watchCount }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <Loading v-if="isLoading" />
    <RewardPopuos :amountList="amountList" :isRewardShow="isRewardShow" @confirm="confirm" @close="isRewardShow = false" />
  </div>
</template>
<script>
import Post from '@/components/Post';
import { AdType, getAdItem } from '@/utils/getConfig';
import AdvSwiper from '@/components/AdvSwiper';
import ImgDecypt from '@/components/ImgDecypt';
// import Comment from "@/components/Comment"
import RewardPopuos from '@/components/RewardPopuos';
import { getVidInfo, reward } from '@/api/video';
import Loading from '@/components/Loading';
import { cancleZan, careCollectVideo, zan } from '@/api/user';
import { Toast } from 'vant';
import { mapGetters } from 'vuex';

export default {
  components: { Post, AdvSwiper, ImgDecypt, RewardPopuos, Loading },
  data() {
    return {
      advList: [],
      isSendShow: false, //发送输入框
      isRewardShow: false, //打赏弹窗
      id: '',
      postInfo: {},
      isLoading: true,
      isRewardRequest: false,
      amountList: [],
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
    }),
  },
  created() {
    this.amountList = this.$store.getters.appConfig.rewardMoney ? this.$store.getters.appConfig.rewardMoney : [2, 10, 20, 50, 100];
    this.advList = getAdItem(AdType.COMMUNITY_DETAILS);
    this.id = this.$route.query.id;
    this.getInfo();
  },
  methods: {
    openShare() {
      this.$store.commit('user/UPDATE_SHARE', {
        show: true,
        info: { ...this.postInfo, isVerScreen: this.postInfo.ratio < 1 },
      });
    },
    message() {
      if (this.userInfo.isVip && this.userInfo.vipLevel > 0) {
        const data = {
          show: true,
          // index: this.index,
          params: {
            videoId: this.postInfo.id,
          },
        };
        this.$store.commit('user/UPDATE_COMMENT', data);
      } else {
        // Toast({
        //   message: "只有会员才能评论哦",
        //   duration: 1500,
        // })
        this.$bus.$emit('promptPopupEvent', '您还不是VIP无法查看神评论');
      }
    },
    async confirm(amount) {
      if (this.isRewardRequest) {
        Toast('正在打赏中...');
        return;
      }

      let req = {
        uid: this.userInfo.uid,
        vid: this.postInfo.id,
        coins: String(amount),
      };
      this.isRewardRequest = true;
      let res = await this.$Api(reward, req);
      this.isRewardRequest = false;
      if (res.code === 200) {
        Toast('打赏成功');
        this.isRewardShow = false;
        this.$store.dispatch('user/getWallet');
        return;
      }
      Toast(res.tip || '打赏失败');
    },
    async getInfo() {
      let req = {
        videoID: this.id,
      };
      let ret = await this.$Api(getVidInfo, req);
      this.isLoading = false;
      if (ret.code === 200) {
        this.postInfo = ret.data;
      }
    },
    getType(type) {
      switch (type) {
        case 'SP':
          return 'video';
        case 'COVER':
          return 'img';
        default:
          return 'video';
      }
    },
    //收藏
    async collecte() {
      let isCollect = this.postInfo?.vidStatus?.hasCollected;
      let id = this.postInfo?.id;
      let req = {
        type: this.getType(this.postInfo.newsType),
        objIds: [id],
        isCollect: !isCollect,
      };
      try {
        let res = await this.$Api(careCollectVideo, req);
        if (res.code === 200) {
          this.postInfo.vidStatus.hasCollected = !isCollect;
          if (isCollect) {
            Toast('取消点赞');
            this.postInfo.forwardCount--;
          } else {
            Toast('点赞成功');
            this.postInfo.forwardCount++;
          }
          return;
        }
        Toast('操作失败');
      } catch (e) {
        Toast('操作失败');
      }
    },
    //点赞
    async like() {
      let isLike = this.postInfo?.vidStatus?.hasLiked;
      let id = this.postInfo.id;
      let req = {
        objID: id,
        type: 'video',
      };
      try {
        if (isLike) {
          let res = await this.$Api(cancleZan, req);
          if (res.code === 200) {
            this.postInfo.vidStatus.hasLiked = false;
            this.postInfo.likeCount--;
            Toast('取消点赞');
            return;
          }
          Toast(res.tip || '取消失败');
        } else {
          let res = await this.$Api(zan, req);
          if (res.code === 200) {
            this.postInfo.vidStatus.hasLiked = true;
            this.postInfo.likeCount++;
            Toast('点赞成功');
            return;
          }
          Toast(res.tip || '点赞失败');
        }
      } catch (e) {
        Toast('操作失败');
      }
    },
    showReward() {
      if (this.postInfo.publisher.uid == this.$store.getters.userInfo.uid) {
        Toast('不能打赏自己');
        return;
      }
      this.isRewardShow = true;
    },
    parReply() {
      this.isSendShow = true;
    },
    success() {
      this.postInfo.commentCount++;
      this.isSendShow = false;
    },
    //转发成功
    forwardSuccess() {
      this.postInfo.forwardCount++;
    },
    unfavorite() {
      this.postInfo.forwardCount--;
    },
  },
};
</script>

<style lang="scss" scoped>
.post_detiils {
  height: 100%;

  .header {
    display: flex;
    height: 44px;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #ffffff;
    margin-bottom: 10px;
    svg {
      font-size: 26px;
    }
    .back {
      position: absolute;
      left: 20px;
      font-size: 24px;
    }

    h2 {
      font-size: 17px;
    }
  }

  .content {
    height: calc(100% - 44px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .post {
      padding: 15px 16px;
    }

    .appreciate {
      background: #ffffff;
      padding-top: 15px;
      padding-bottom: 18px;
      text-align: center;

      h2 {
        font-size: 14px;
        color: #999999;
        padding-bottom: 14px;
      }

      .appreciate_btn {
        margin: 0 auto;
        width: 200px;
        height: 35px;
        background: #ff678f;
        border-radius: 30px;
        font-family: 'PingFangHK-Regular';
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;

        .avatar {
          width: 25px;
          height: 25px;
          padding-right: 4px;
        }
      }
    }

    .adv {
      padding: 12px 0 16px 0;
      margin-bottom: 80px;
      /deep/ .swiper-container {
        height: 136px;
      }
      /deep/ img {
        height: 100%;
      }
    }
  }

  .bottom_menu {
    width: 100%;
    height: 70px;
    background: #ffffff;
    position: absolute;
    bottom: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    box-sizing: border-box;
    .views {
      width: 120px;
      font-size: 12px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      color: #666666;
      svg {
        font-size: 20px;
      }
    }
    .menu_box {
      display: flex;
      flex: 1;
      justify-content: space-evenly;
    }

    .menu_item {
      text-align: center;
      // width: 80px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'PingFangHK-Regular';
      color: #666666;

      svg {
        font-size: 20px;
      }

      span {
        padding-left: 6px;
      }
    }
  }

  .comment {
    padding: 16px;

    h2 {
      font-size: 18px;
    }
  }
}
</style>
